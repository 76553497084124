/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { cn } from "@/app/_utils/ui";
import { DialogContent } from "@/app/_components/ui/dialog";
import { H2 } from "../typography/h2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { AppDispatch } from "../../reducers/store";
import { HuddleEdit, HuddleGroup } from "@/app/_types";
import { getCurrentDateTime } from "@/app/_utils/helper";
import {
  fetchHuddleGroups,
  startOrJoinHuddle,
  setHuddleId,
  setHuddleTime,
  setTopicArticle,
  undoJoinHuddle,
  editHuddle,
} from "../../reducers/huddleSlice";
import { fetchUserHuddles } from "../../reducers/userHuddlesSlice";
import ButtonWithCancel from "../buttons/ButtonWithCancel";
import ChangeHuddle from "./ChangeHuddle";
import FindHuddle from "./FindHuddle";
import HuddleCreated from "./HuddleCreated";
import HuddleComplete from "./HuddleComplete";
import wait from "waait";
import { parseDateTimeStringTo12Hour } from "@/app/_utils/formatter";

const HuddleDialogContent = ({
  start,
  show,
  onClose,
}: {
  start?: boolean;
  show: boolean;
  onClose: () => void;
}) => {
  console.log("HuddleDialogContent");
  const [isLoading, setIsLoading] = useState(true);
  const [isHuddleStarter, setIsHuddleStarter] = useState(false);
  const [changeHuddle, setChangeHuddle] = useState(false);
  const [findHuddle, setFindHuddle] = useState(false);
  const [isHuddleComplete, setIsHuddleComplete] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const huddleGroups = useSelector(
    (state: RootState) => state.huddleGroups.huddleGroups,
  );
  const selectedLocation = useSelector(
    (state: RootState) => state.locationDashboard.selectedLocation,
  );
  const articleId = useSelector(
    (state: RootState) => state.dailyArticle.article.id,
  );
  const articles = useSelector((state: RootState) => state.articles);
  const dailyArticle = articles.articles[articleId];

  const [hasAttempted, setHasAttempted] = useState(false);
  const [selectedHuddle, setSelectedHuddle] = useState<HuddleGroup | undefined>(
    undefined,
  );

  const undoHuddle = async (huddle: HuddleGroup) => {
    if (!accessToken) {
      throw new Error("No access token");
    }

    await dispatch(
      undoJoinHuddle({
        articleId: huddle.ArticleId,
        body: {
          huddleId: huddle.huddleId,
          huddleTime: huddle.huddleTime.replace("+00:00", ".000"),
        },
        accessToken,
      }),
    ).unwrap();
  };

  const startNewHuddle = async (
    accessToken: string | null,
    selectedLocation: { name: string } | null,
    dailyArticle: { id: string },
  ) => {
    if (!accessToken) {
      throw new Error("No access token");
    }

    console.log("startNewHuddle called");

    const { currentDateString, currentTimeString } = getCurrentDateTime();
    const huddleTime = `${currentDateString}T${currentTimeString}`;
    const huddleDate = new Date(huddleTime);

    const body = {
      huddleTime: huddleDate.toISOString().slice(0, -1),
      location: selectedLocation?.name || "",
    };

    dispatch(setHuddleId(null));
    dispatch(setHuddleTime(huddleTime));
    dispatch(setTopicArticle(dailyArticle));

    try {
      const huddleResponse = await dispatch(
        startOrJoinHuddle({
          articleId: dailyArticle.id,
          body,
          accessToken,
          type: "start",
        }),
      ).unwrap();

      console.log("Huddle response:", huddleResponse);
      const huddleId = huddleResponse.huddle_item.PK.split("#")[1];

      setSelectedHuddle({
        ...huddleResponse.huddle_item,
        huddleTime,
        huddleId,
      });
      setIsHuddleStarter(true);
      await refreshSelectedHuddle({ accessToken, huddleId });
    } catch (error: unknown) {
      if (
        error instanceof String &&
        error.toLowerCase().includes("already exists")
      ) {
        setIsHuddleStarter(true);
      } else {
        console.error("Error starting huddle:", error);
      }
    }
  };

  const refreshSelectedHuddle = async ({
    accessToken,
    huddleId,
  }: {
    accessToken: string;
    huddleId: string;
  }) => {
    // get updated huddle data from the server after starting or joining
    const huddleGroups = await dispatch(
      fetchHuddleGroups(accessToken),
    ).unwrap();
    await dispatch(fetchUserHuddles(accessToken));
    const updatedHuddle: HuddleGroup = huddleGroups.find(
      (huddle: HuddleGroup) => huddle.huddleId === huddleId,
    );
    console.log("refreshSelectedHuddle", {
      huddleGroups,
      huddleId,
      updatedHuddle,
    });
    setSelectedHuddle(updatedHuddle);
  };

  const closeDialog = async () => {
    onClose();
    await wait(1000);
    setHasAttempted(false); // reset so another huddle can be started or joined
  };

  useEffect(() => {
    async function fetchHuddles() {
      if (accessToken && selectedLocation) {
        await dispatch(fetchHuddleGroups(accessToken));
        setIsLoading(false);
      }
    }

    if (accessToken && selectedLocation) {
      fetchHuddles();
    }
  }, [accessToken, selectedLocation]);

  useEffect(() => {
    if (!isLoading && start) {
      console.log("useEffect loading complete, start clicked", {
        isLoading,
        start,
        hasAttempted,
        selectedLocation,
        accessToken,
        show,
        selectedHuddle,
        isHuddleComplete,
      });
    }
    if (
      !isLoading && // Ensure the fetch process has completed
      !hasAttempted && // Ensure this only runs once
      selectedLocation &&
      start &&
      accessToken
    ) {
      console.log("attempting to start huddle");
      setHasAttempted(true); // Avoid re-running

      const huddlesAtLocation = huddleGroups.filter(
        (huddle: HuddleGroup) => huddle.Location === selectedLocation.name,
      );

      if (huddlesAtLocation.length > 0) {
        const huddleToJoin: HuddleGroup = huddlesAtLocation[0];
        console.log({ huddlesAtLocation });
        setSelectedHuddle(huddleToJoin);
        if (!huddleToJoin.UserJoined) {
          console.log("user had not joined, so attempt to join");
          const huddleId = huddleToJoin.huddleId;
          const huddleTime = huddleToJoin.huddleTime.split("+")[0];
          const body = {
            huddleId,
            huddleTime,
            location: selectedLocation.name,
          };
          dispatch(setHuddleId(huddleId));
          dispatch(setHuddleTime(huddleTime));
          dispatch(
            startOrJoinHuddle({
              articleId,
              body,
              accessToken,
              type: "join",
            }),
          )
            .unwrap()
            .then(async () => {
              await refreshSelectedHuddle({ accessToken, huddleId });
            });
        } else {
          console.log("user had already joined, so do nothing");
        }
      } else {
        // No huddle exists, so start a new one
        console.log("No huddle at location, so start a new one");
        startNewHuddle(accessToken, selectedLocation, dailyArticle);
      }
    }
  }, [isLoading, hasAttempted, selectedLocation, start]);

  // Use useEffect to update selectedHuddle when huddleGroups or selectedLocation changes
  useEffect(() => {
    if (huddleGroups && selectedLocation) {
      const huddlesAtLocation = huddleGroups.filter(
        (huddle: HuddleGroup) => huddle.Location === selectedLocation.name,
      );

      if (huddlesAtLocation.length > 0) {
        const currentTime = new Date(); // Current time

        // Filter huddles that are in the past or now
        const pastOrCurrentHuddles: HuddleGroup[] = huddlesAtLocation.filter(
          (huddle) => {
            const huddleTime = new Date(huddle.localHuddleTime); // Parse huddle time
            return huddleTime <= currentTime; // Only huddles that are in the past or now
          },
        );

        // If there are any past or current huddles, select the most recent one
        if (pastOrCurrentHuddles.length > 0) {
          const mostRecentHuddle: HuddleGroup = pastOrCurrentHuddles.reduce(
            (latestHuddle, currentHuddle) => {
              const latestHuddleTime = new Date(latestHuddle.localHuddleTime);
              const currentHuddleTime = new Date(currentHuddle.localHuddleTime);

              return currentHuddleTime > latestHuddleTime
                ? currentHuddle
                : latestHuddle;
            },
          );
          setSelectedHuddle(mostRecentHuddle);
        } else {
          // If no past or current huddles, fallback to the earliest future huddle
          const earliestFutureHuddle = huddlesAtLocation.reduce(
            (earliestHuddle, currentHuddle) => {
              const earliestHuddleTime = new Date(
                earliestHuddle.localHuddleTime,
              );
              const currentHuddleTime = new Date(currentHuddle.localHuddleTime);
              return currentHuddleTime < earliestHuddleTime
                ? currentHuddle
                : earliestHuddle;
            },
          );
          setSelectedHuddle(earliestFutureHuddle);
        }
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    async function closeHuddleAfterWait() {
      await wait(8100);
      await closeDialog();
    }

    if (isHuddleComplete) {
      closeHuddleAfterWait();
    }
  }, [isHuddleComplete]);

  if (!selectedHuddle || !show) return null;

  return (
    <DialogContent
      className={cn("transition-all ease-in-out duration-300 max-w-[390px]")}
    >
      <div
        id="oneClickHuddle"
        className="p-10 text-dark text-center flex flex-col justify-center items-center"
      >
        {isHuddleComplete ? (
          <HuddleComplete
            isStarter={isHuddleStarter}
            articleId={selectedHuddle.ArticleId}
          />
        ) : (
          <>
            {changeHuddle || findHuddle ? (
              <>
                {findHuddle && (
                  <FindHuddle
                    onJoin={async (huddle: HuddleGroup) => {
                      if (!accessToken) {
                        throw new Error("No access token");
                      }
                      dispatch(
                        startOrJoinHuddle({
                          articleId,
                          body: {
                            huddleId: huddle.huddleId,
                            huddleTime: huddle.huddleTime,
                            location: huddle.Location,
                          },
                          accessToken,
                          type: "join",
                        }),
                      )
                        .unwrap()
                        .then(async () => {
                          await dispatch(fetchUserHuddles(accessToken));
                        });
                    }}
                  >
                    <button
                      onClick={() => {
                        undoHuddle(selectedHuddle);
                        setFindHuddle(false);
                        setChangeHuddle(true);
                      }}
                      className="text-blue-tint text-sm underline"
                    >
                      Want to lead? Start a huddle
                    </button>
                  </FindHuddle>
                )}
                {changeHuddle && (
                  <ChangeHuddle
                    selectedHuddle={selectedHuddle}
                    onCancel={() => setChangeHuddle(false)}
                    onSave={async (
                      huddle: HuddleEdit,
                      huddleTimeOriginal: string,
                    ) => {
                      if (accessToken) {
                        const editHuddleResponse = await dispatch(
                          editHuddle({
                            huddleId: selectedHuddle.huddleId,
                            huddleTimeOriginal: huddleTimeOriginal.replace(
                              "+00:00",
                              ".000",
                            ),
                            body: {
                              articleId: huddle.articleId,
                              huddleTime: huddle.huddleTime.replace(
                                "+00:00",
                                ".000",
                              ),
                              location: huddle.location,
                              leader: {
                                location: huddle.leader.location,
                                lastName: huddle.leader.lastName,
                                SK: huddle.leader.SK,
                                PK: huddle.leader.PK,
                                firstName: huddle.leader.firstName,
                              },
                            },
                            accessToken,
                          }),
                        ).unwrap();
                        setSelectedHuddle({
                          ...editHuddleResponse,
                          huddleTime: huddle.huddleTime,
                          huddleId: selectedHuddle.huddleId,
                        });
                        setIsHuddleComplete(true);
                      }
                    }}
                  >
                    <button
                      onClick={async () => {
                        await undoHuddle(selectedHuddle);
                        await dispatch(fetchHuddleGroups(accessToken));
                        setChangeHuddle(false);
                        setFindHuddle(true);
                      }}
                      className="text-blue-tint text-sm underline -mt-4 mb-4"
                    >
                      Join a different huddle?
                    </button>
                  </ChangeHuddle>
                )}
              </>
            ) : (
              <>
                {selectedHuddle?.UserJoined && !isHuddleStarter ? (
                  <div className="flex flex-col items-start text-left w-full">
                    <H2>You Already Huddled Today!</H2>
                    <p className="mb-8">Did you want to huddle again?</p>
                    <div className="bg-teal-50 text-sm p-4 flex w-full gap-2 rounded">
                      <div className="w-12 h-12 relative">
                        <Image
                          fill={true}
                          className="object-contain"
                          alt=""
                          src={`/assets/images/icons/huddle/huddle-icon-success.svg`}
                        />
                      </div>
                      <div className="grow text-left w-full">
                        <div>
                          <span className="font-bold">
                            {selectedHuddle.Location}
                          </span>{" "}
                          at{" "}
                          {parseDateTimeStringTo12Hour(
                            selectedHuddle.huddleTime,
                          )}
                        </div>
                        <div>{selectedHuddle.ArticleTitle}</div>
                      </div>
                    </div>
                    <ButtonWithCancel
                      onCancel={async () => {
                        await closeDialog();
                      }}
                      onClick={() =>
                        startNewHuddle(
                          accessToken,
                          selectedLocation,
                          dailyArticle,
                        )
                      }
                      className="pt-8"
                    >
                      Huddle Again
                    </ButtonWithCancel>
                  </div>
                ) : (
                  <HuddleCreated
                    isHuddleStarter={isHuddleStarter}
                    onClickChange={() => {
                      if (!isHuddleStarter) {
                        undoHuddle(selectedHuddle);
                      }
                      setChangeHuddle(true);
                    }}
                    onTimeout={() => setIsHuddleComplete(true)}
                    huddle={selectedHuddle}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </DialogContent>
  );
};

export default HuddleDialogContent;

import React from "react";
import { cn } from "@/app/_utils/ui";

const VaultIcon = ({
  width = "20",
  height = "21",
  fill = "none",
  className = "",
}) => (
  <span className={cn("relative -left-[2px]", className)}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M5.18896 16.5186V18H7.70748V16.5186"
              stroke="white"
              strokeWidth="1.2"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.4481 16.5186V18H14.9666V16.5186"
              stroke="white"
              strokeWidth="1.2"
              strokeLinejoin="round"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_3"
              d="M16.2422 16.5467C16.8629 16.5467 17.3651 16.0444 17.3666 15.4252V3.12296C17.3666 2.50222 16.8644 2 16.2437 2H3.94144C3.3207 2 2.81848 2.50222 2.81848 3.12296V15.4237C2.81848 16.0444 3.3207 16.5467 3.94144 16.5467M16.2422 16.5467C15.8002 16.5467 15.3761 16.5467 14.9666 16.5467H16.2422ZM3.94144 16.5467C4.37328 16.5467 4.78806 16.5467 5.18885 16.5467H3.94144ZM7.70737 16.5467C9.28485 16.5467 10.8723 16.5467 12.4481 16.5467"
              stroke="white"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="Group_4">
            <path
              id="Subtract"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.0908 5.34668V6.8581L8.02268 5.78998L6.60846 7.20419L7.67658 8.27232H6.16516V10.2723H7.67862L6.60846 11.3425L8.02268 12.7567L9.0908 11.6886V13.2H11.0908V11.6865L12.161 12.7567L13.5752 11.3425L12.505 10.2723H14.0185V8.27232H12.507L13.5752 7.20419L12.161 5.78998L11.0908 6.86014V5.34668H9.0908ZM12.3082 9.27241C12.3082 10.497 11.3154 11.4897 10.0908 11.4897C8.86623 11.4897 7.8735 10.497 7.8735 9.27241C7.8735 8.04781 8.86623 7.05508 10.0908 7.05508C11.3154 7.05508 12.3082 8.04781 12.3082 9.27241Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M10.091 11.2858C11.2029 11.2858 12.1043 10.3844 12.1043 9.27251C12.1043 8.16058 11.2029 7.25918 10.091 7.25918C8.97906 7.25918 8.07766 8.16058 8.07766 9.27251C8.07766 10.3844 8.97906 11.2858 10.091 11.2858Z"
              stroke="white"
              strokeWidth="0.8"
              strokeMiterlimit="10"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default VaultIcon;

import React from "react";

const GamesIcon = ({
  width = "20",
  height = "21",
  fill = "white",
  className = "",
}) => (
  <span className={className}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0462 2.88348C10.3121 2.88348 8.90538 4.28512 8.89711 6.01722L8.89355 6.76364L5.01685 6.76363L5.01685 20.8609L19.114 20.8609V18.3915C16.8747 18.0572 15.1572 16.126 15.1572 13.7936C15.1572 11.4612 16.8747 9.52993 19.114 9.1956V6.76364L15.1989 6.76364L15.1953 6.01722C15.1871 4.28512 13.7803 2.88348 12.0462 2.88348ZM7.46038 5.26363C7.82688 3.0619 9.74053 1.38348 12.0462 1.38348C14.3519 1.38348 16.2655 3.0619 16.632 5.26364L19.267 5.26364C20.0109 5.26364 20.614 5.86673 20.614 6.61067V10.6558L19.8531 10.6447C19.8376 10.6445 19.822 10.6444 19.8063 10.6444C18.0671 10.6444 16.6572 12.0543 16.6572 13.7936C16.6572 15.5328 18.0671 16.9427 19.8063 16.9427C19.822 16.9427 19.8376 16.9426 19.8531 16.9424L20.614 16.9313V21.0138C20.614 21.7578 20.0109 22.3609 19.267 22.3609L4.86388 22.3609C4.11993 22.3609 3.51685 21.7578 3.51685 21.0138L3.51685 6.61067C3.51685 5.86672 4.11994 5.26363 4.86388 5.26363L7.46038 5.26363Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default GamesIcon;

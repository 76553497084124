import React, { useEffect, useRef } from "react";
import { H2 } from "../typography/h2";
import HuddleInfo from "./HuddleInfo";
import { HuddleGroup } from "@/app/_types";

const HuddleCreated = ({
  isHuddleStarter,
  huddle,
  onClickChange,
  onTimeout,
}: {
  isHuddleStarter: boolean;
  huddle: HuddleGroup;
  onClickChange: () => void;
  onTimeout: () => void;
}) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const isClickedRef = useRef(isClicked);

  useEffect(() => {
    isClickedRef.current = isClicked;
  }, [isClicked]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isClickedRef.current) {
        onTimeout();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!huddle) return null;

  return (
    <>
      <H2>
        {isHuddleStarter ? "You’re a Huddle Starter!" : "You’re A Huddler!"}
      </H2>
      <div className="text-sm text-neutral-700 mb-4">
        +{isHuddleStarter ? "10" : "8"} points
      </div>
      <HuddleInfo huddle={huddle} />
      <div>
        <button
          onClick={() => {
            setIsClicked(true);
            onClickChange();
          }}
          className="text-blue-tint text-sm underline mt-8"
        >
          Problem with the huddle? Change it.
        </button>
      </div>
    </>
  );
};

export default HuddleCreated;

import React, { useState, useEffect } from "react";
import { cn } from "@/app/_utils/ui";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../reducers/store";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { H2 } from "../typography/h2";
import { HuddleGroup } from "@/app/_types";
import { Button } from "../buttons/button";
import LocationSearchInput from "../search/LocationSearchInput";
import HuddleInfo from "./HuddleInfo";

const FindHuddle = ({
  onJoin,
  children,
}: {
  onJoin: (huddle: HuddleGroup) => void;
  children?: React.ReactNode;
}) => {
  const [searchCount, setSearchCount] = useState(0);
  const [filteredHuddleGroups, setFilteredHuddleGroups] = useState<
    HuddleGroup[]
  >([]);

  const huddleGroups = useSelector(
    (state: RootState) => state.huddleGroups.huddleGroups,
  );

  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const newFilteredHuddleGroups = selectedLocation
      ? huddleGroups.filter(
          (huddle) => huddle.Location === selectedLocation.name,
        )
      : huddleGroups;

    setFilteredHuddleGroups(newFilteredHuddleGroups);
  }, [searchCount, huddleGroups]);

  return (
    <div id="FindHuddle" className="w-full flex flex-col gap-4">
      <H2>Find a Huddle</H2>
      <div className="pb-4">
        <LocationSearchInput
          initialValue={selectedLocation?.name || ""}
          autoFocus={false}
          additionalFilter={(item) => !item.name.includes("VQ -")}
          onSelectLocation={async (location) => {
            await dispatch(setSelectedLocation(location));
            setSearchCount((prevCount) => prevCount + 1);
          }}
        />
      </div>
      {filteredHuddleGroups.map((huddle, index) => (
        <div
          key={huddle.huddleId}
          className={cn(index !== 0 && "border-t pt-4")}
        >
          <HuddleInfo huddle={huddle} />
          <Button
            className="my-4"
            onClick={() => onJoin(huddle)}
            variant="primary"
          >
            Join
          </Button>
        </div>
      ))}
      {children}
    </div>
  );
};

export default FindHuddle;

import React, { useEffect, useState } from "react";
import { Input } from "../forms/input";
import LocationSearchInput from "../search/LocationSearchInput";
import ArticleSearch, { ArticleProps } from "../search/ArticleSearch";
import { H2 } from "../typography/h2";
import { HuddleGroup, HuddleEdit, HuddleLeader } from "@/app/_types";
import ButtonWithCancel from "../buttons/ButtonWithCancel";
import SearchAutocomplete from "../search/SearchAutocomplete";
import { getCurrentDateTime } from "@/app/_utils/helper";
import { RootState, AppDispatch } from "../../reducers/store";
import { fetchUsersByLocation } from "../../reducers/huddleSlice";
import { useSelector, useDispatch } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

interface HuddleLeaderWithFullName extends HuddleLeader {
  fullName: string;
}

const ChangeHuddle = ({
  selectedHuddle,
  onCancel,
  onSave,
  children,
}: {
  selectedHuddle: HuddleGroup;
  onCancel: () => void;
  onSave: (huddleEdit: HuddleEdit, huddleTimeOriginal: string) => void;
  children?: React.ReactNode;
}) => {
  const initialHuddleLeader: HuddleLeaderWithFullName = {
    firstName: selectedHuddle.Leader.firstName,
    lastName: selectedHuddle.Leader.lastName,
    location: selectedHuddle.Location,
    PK: selectedHuddle.Leader.PK,
    SK: selectedHuddle.Leader.SK,
    fullName: selectedHuddle.LeaderName,
  };

  const [isSaving, setIsSaving] = useState(false);
  const [huddle, setHuddle] = useState<HuddleGroup>(selectedHuddle);
  const [huddleLeaders, setHuddleLeaders] = useState<
    HuddleLeaderWithFullName[]
  >([
    {
      ...initialHuddleLeader,
      fullName: `${initialHuddleLeader.firstName} ${initialHuddleLeader.lastName}`,
    },
  ]);
  const [selectedLeader, setSelectedLeader] =
    useState<HuddleLeaderWithFullName>(initialHuddleLeader);
  const [selectedArticle, setSelectedArticle] = useState<ArticleProps>({
    articleId: selectedHuddle.ArticleId,
    title: selectedHuddle.ArticleTitle,
  });

  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );
  const initialDate = huddle.huddleTime
    ? huddle.huddleTime.split("T")[0]
    : getCurrentDateTime().currentDateString;
  const initialTime = huddle.huddleTime
    ? huddle.huddleTime.split("T")[1].substring(0, 5)
    : getCurrentDateTime().currentTimeString;

  const [selectedDate, setSelectedDate] = useState<string>(initialDate);
  const [selectedTime, setSelectedTime] = useState<string>(initialTime);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchHuddleLeaders = async () => {
      if (selectedLocation && accessToken) {
        const response = await dispatch(
          fetchUsersByLocation({
            accessToken,
            location: selectedLocation.name,
          }),
        ).unwrap();

        if (response) {
          const leadersWithFullName = response.map((leader: HuddleLeader) => ({
            ...leader,
            fullName: `${leader.firstName} ${leader.lastName}`,
          }));
          setHuddleLeaders(leadersWithFullName);
        }
      }
    };

    fetchHuddleLeaders();
  }, [selectedLocation, accessToken, dispatch]);

  return (
    <div id="changeHuddle" className="w-full flex flex-col gap-4">
      <H2>Start Huddle</H2>
      {children}
      <div className="w-full text-left relative z-30">
        <label className="block text-neutral-700 text-sm mb-1">
          <span className="text-red-600">*</span> Location:
        </label>
        <LocationSearchInput
          onSelectLocation={(location) => {
            setHuddle((prevHuddle) => ({
              ...prevHuddle,
              Location: location.name,
              Timezone: location.timezone,
            }));
          }}
        />
      </div>

      <div className="w-full text-left">
        <label className="block text-neutral-700 text-sm mb-1">Topic:</label>
        <div className="w-full z-20 relative">
          <ArticleSearch
            initialArticle={{
              articleId: selectedArticle.articleId,
              title: selectedArticle.title,
            }}
            onSelectArticle={(article) => {
              setSelectedArticle(article);
            }}
            autoFocus={false}
            portal={true}
          />
        </div>
      </div>

      <div className="w-full text-left">
        <label className="block text-neutral-700 text-sm mb-1">
          <span className="text-red-600">*</span> Date/Time:
        </label>
        <div className="grid grid-cols-2 gap-[7px]">
          <Input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="block"
          />
          <Input
            type="time"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            className="block"
          />
        </div>
      </div>

      <div className="w-full text-left">
        <label className="block text-neutral-700 text-sm mb-1">
          <span className="text-red-600">*</span> Leader:
        </label>
        <SearchAutocomplete
          data={huddleLeaders}
          valueKey="fullName"
          initialValue={initialHuddleLeader.fullName}
          onSelect={setSelectedLeader}
          icon={<UserOutlined />}
          portal={true}
        />
      </div>

      <ButtonWithCancel
        className="pt-4"
        disabled={isSaving}
        onCancel={onCancel}
        onClick={() => {
          setIsSaving(true);
          onSave(
            {
              huddleTime: `${selectedDate}T${selectedTime}`,
              location: huddle.Location,
              leader: selectedLeader,
              articleId: parseInt(selectedArticle.articleId),
            },
            huddle.huddleTime.replace(" ", "+"),
          );
        }}
      >
        Save Huddle
      </ButtonWithCancel>
    </div>
  );
};

export default ChangeHuddle;

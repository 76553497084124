import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle } from "@/app/_components/ui/dialog";
import Image from "next/image";
import wait from "waait";
import { Button } from "../buttons/button";
import HuddleButton from "./HuddleButton";
import HuddleDialogContent from "./HuddleDialogContent";
import HuddleSuccessAnimation from "./HuddleSuccessAnimation";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

const LaunchHuddle = ({ isCircleButton }: { isCircleButton?: boolean }) => {
  const [animateStart, setAnimateStart] = useState(false);
  const [animateComplete, setAnimateComplete] = useState(false);
  const [open, setOpen] = useState(false);

  const onClickHuddle = () => {
    setAnimateStart(true);
    setAnimateComplete(false);
  };

  useEffect(() => {
    async function playAnimation() {
      await wait(3000);
      setOpen(true);
      setAnimateStart(false);
      setAnimateComplete(true);
    }

    if (animateStart) {
      playAnimation();
    }
  }, [animateStart]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTitle>
        <VisuallyHidden>Huddle</VisuallyHidden>
      </DialogTitle>
      {isCircleButton ? (
        <HuddleButton onClick={onClickHuddle} />
      ) : (
        <Button
          className="items-center h-[45px] w-[250px] md:w-[124px]"
          id="startHuddle"
          onClick={onClickHuddle}
        >
          <Image
            width={24}
            height={24}
            alt=""
            src="/assets/images/icons/huddle/rocket-icon.svg"
            className="mr-2"
          />{" "}
          <span className="pt-0.5">Huddle</span>
        </Button>
      )}
      {animateStart && <HuddleSuccessAnimation />}
      <HuddleDialogContent
        start={animateStart}
        show={animateComplete}
        onClose={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default LaunchHuddle;

import React from "react";
import { Button } from "../buttons/button";
import { cn } from "@/app/_utils/ui";

const ButtonWithCancel = ({
  onCancel,
  onClick,
  children,
  className,
  disabled,
}: {
  onCancel: () => void;
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={cn(
        "flex gap-4 w-full justify-end",
        className,
        disabled && "pointer-events-none",
      )}
    >
      <Button disabled={disabled} variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button disabled={disabled} variant="primary" onClick={onClick}>
        {children}
      </Button>
    </div>
  );
};

export default ButtonWithCancel;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { VEGWIDESTATS_ENDPOINT } from "@/app/_consts/internal";

interface StatDetail {
  count: number;
  name: string;
  id: string;
}

interface VegWideStats {
  perfect_huddler: Record<string, StatDetail>;
  bookworm: Record<string, StatDetail>;
  huddle_starter: Record<string, StatDetail>;
}

interface VegStatsState {
  isLoading: boolean;
  vegWideStats: {
    perfectHuddler: Record<string, StatDetail>;
    bookworm: Record<string, StatDetail>;
    huddleStarter: Record<string, StatDetail>;
  };
}

const initialState: VegStatsState = {
  isLoading: true,
  vegWideStats: {
    perfectHuddler: {},
    bookworm: {},
    huddleStarter: {},
  },
};

export const fetchVegWideStats = createAsyncThunk<VegWideStats, string>(
  "vegStats/fetchVegWideStats",
  async (accessToken) => {
    const response = await axios.get(VEGWIDESTATS_ENDPOINT, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

const vegStatsSlice = createSlice({
  name: "vegStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVegWideStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVegWideStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vegWideStats.perfectHuddler = {};
        state.vegWideStats.bookworm = {};
        state.vegWideStats.huddleStarter = {};

        const stats = action.payload;
        if (stats.perfect_huddler) {
          state.vegWideStats.perfectHuddler = stats.perfect_huddler;
        }
        if (stats.bookworm) {
          state.vegWideStats.bookworm = stats.bookworm;
        }
        if (stats.huddle_starter) {
          state.vegWideStats.huddleStarter = stats.huddle_starter;
        }
      })
      .addCase(fetchVegWideStats.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default vegStatsSlice.reducer;

import { useSelector } from "react-redux";
import { RootState } from "../reducers/store";

export const useHasEditPrivileges = () => {
  const userReadArticles = useSelector(
    (state: RootState) => state.userReadArticles,
  );

  const userPermissions = userReadArticles.userInfo
    ? userReadArticles.userInfo.permissions
    : [];

  return userPermissions?.includes("edit");
};

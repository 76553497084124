import React from "react";
import RocketIcon from "../graphics/RocketIcon";

const HuddleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      id="huddleButton"
      className="text-center text-neutral-700 sm:text-white sm:font-medium text-xs active:scale-95 transition-all duration-100 relative -top-4 sm:top-0 pointer-events-auto"
    >
      <div className="w-[60px] h-[60px] sm:w-[45px] sm:h-[45px] shadow-xl mb-1 flex justify-center items-center rounded-full bg-red-secondary">
        <RocketIcon className="relative sm:scale-[.875] top-px right-px" />
      </div>
      Huddle
    </button>
  );
};

export default HuddleButton;

import React from "react";
import { cn } from "@/app/_utils/ui";
import {
  getInitials,
  getLastNameInitial,
  formatDateTime,
} from "@/app/_utils/formatter";
import { HuddleGroup } from "@/app/_types";

const HuddleInfo = ({ huddle }: { huddle: HuddleGroup }) => {
  if (!huddle) return null;

  const huddlerNames = huddle.Huddlers
    ? Object.values(huddle.Huddlers).map(
        (huddler: { name: string }) => huddler.name,
      )
    : [];

  if (huddlerNames.length === 0) return null;

  if (huddlerNames.length > 2) {
    huddlerNames[2] = `+${huddlerNames.length - 2}`;
  }

  return (
    <>
      <div className="flex justify-center">
        {huddlerNames.map((name, index) => (
          <div
            className={cn(
              "rounded-full w-20 h-20 text-2xl -mx-[10px] flex items-center justify-center font-display font-bold bg-red-600 border-4 border-white",
              index === 1 && "bg-teal-600",
              index === 2 && "bg-gold-400",
            )}
            key={name}
          >
            {getInitials(name)}
          </div>
        ))}
      </div>
      <div className="font-bold text-xl mt-2">{huddle.Location}</div>
      <div>
        {getLastNameInitial(huddle.LeaderName || "")} |{" "}
        {formatDateTime(huddle.huddleTime)}
      </div>
      <div className="line-clamp-1">{huddle.ArticleTitle}</div>
    </>
  );
};

export default HuddleInfo;

import * as Portal from "@radix-ui/react-portal";
import React from "react";
import Lottie from "lottie-react";
import FinishedHuddleMobile from "../animation/FinishedHuddleMobile.json";
import FinishedHuddleDesktop from "../animation/FinishedHuddleDesktop.json";

const HuddleSuccessAnimation: React.FC = () => {
  return (
    <Portal.Root className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
      <div className="w-full h-full">
        <div className="block md:hidden w-full h-full">
          <Lottie animationData={FinishedHuddleMobile} />
        </div>
        <div className="hidden md:block w-full h-full">
          <Lottie animationData={FinishedHuddleDesktop} />
        </div>
      </div>
    </Portal.Root>
  );
};

export default HuddleSuccessAnimation;

import {
  TOKEN_RESPONSE_CONFIG,
  refreshToken,
} from "../_components/reducers/loginSlice";
import { AppDispatch } from "../_components/reducers/store";

export const handleLoginError = (dispatch: AppDispatch) => {
  const tokenString = localStorage.getItem(TOKEN_RESPONSE_CONFIG);
  const { refresh_token } = JSON.parse(tokenString || "{}");

  if (refresh_token) {
    dispatch(refreshToken(refresh_token)); // Use refreshToken here
  } else {
    localStorage.removeItem(TOKEN_RESPONSE_CONFIG);
    window.location.reload();
  }
};
